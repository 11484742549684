<template>
  <div>
    <h3 v-if="currentApartment">{{ $t("readings.title", {name: getCurrentTenantName(currentApartment)}) }}</h3>
    <el-card class="box-card"
             v-if="isGranted('ROLE_MANAGER')  || (isOwner(currentUser, currentCondominium)) || isSupplier(currentUser, currentCondominium) || isServiceTechnician(currentUser, currentCondominium)">
      <el-button type="primary" icon="el-icon-plus" v-if="isGranted('ROLE_MANAGER')" @click="openCreateReadingDialog">
        Aggiungi Lettura
      </el-button>
      <el-button
          v-if="viewMode==='list' && (isGranted('ROLE_MANAGER') || (isOwner(currentUser, currentCondominium)) || isSupplier(currentUser, currentCondominium) || isServiceTechnician(currentUser, currentCondominium))"
          :type="showErrors?'primary':''"
          class="ml-10"
          @click="toggleShowErrors"
      >
        Mostra Errori
      </el-button>
    </el-card>
    <el-card class="box-card">
      <div class="reading-controls">
        <div>
          <el-form :inline="true" label-width="auto" @submit.prevent>
            <el-form-item label="Periodo">
              <el-date-picker
                  v-model="period"
                  type="daterange"
                  start-placeholder="Data inizio"
                  end-placeholder="Data fine"
                  format="DD/MM/YYYY"
                  value-format="DD/MM/YYYY"
                  range-separator="-"
                  unlink-panels
                  @change="fetchData"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="Stagione">
              <el-button-group>
                <el-button type="primary" icon="el-icon-minus" @click="setPreviousSeason"></el-button>
                <el-button type="primary" icon="el-icon-plus" @click="setNextSeason"></el-button>
              </el-button-group>
            </el-form-item>
          </el-form>
        </div>
        <div>
          <el-button-group class="ml-10">
            <el-button :type="grouping === 'monthly' ? 'primary' : 'default'"
                       @click="grouping = 'monthly'">
              Mensile
            </el-button>
            <el-button :type="grouping === 'all' ? 'primary' : 'default'"
                       @click="grouping = 'all'">
              Tutte
            </el-button>
          </el-button-group>
          <el-button-group class="ml-10">
            <el-button :type="viewMode === 'list' ? 'primary' : 'default'" @click="viewMode = 'list'"
                       icon="el-icon-data-tickets">Tabelle
            </el-button>
            <el-button :type="viewMode === 'chart' ? 'primary' : 'default'" @click="viewMode = 'chart'"
                       icon="el-icon-data-line">Grafici
            </el-button>
          </el-button-group>
          <div style="padding-top: 4px; padding-bottom: 4px">
            <el-button class="ml-10" @click="compare" :type="compareMode?'primary':''">
              Confronto
            </el-button>
          </div>

        </div>
      </div>

      <ReadingsAll v-show="viewMode==='list' && grouping==='all'"></ReadingsAll>
      <MonthlyTable v-show="viewMode==='list' && grouping==='monthly'"></MonthlyTable>
      <ReadingsChart v-show="viewMode==='chart' && grouping==='all'"></ReadingsChart>
      <MonthlyChart v-show="viewMode==='chart' && grouping==='monthly'"></MonthlyChart>
    </el-card>

    <create-reading-dialog ref="createReadingDialog" v-if="isGranted('ROLE_MANAGER')"></create-reading-dialog>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import DateMixin from "../../mixins/DateMixin";
import UserMixin from "../../mixins/UserMixin";
import CreateReadingDialog from "../../components/Dialogs/CreateReadingDialog";
import moment from "moment";
import ReadingsAll from "../../components/Readings/ReadingsAll.vue";
import UtilsMixin from "@/mixins/UtilsMixin";
import {useReadingsStore} from "@/store/useReadingsStore";
import {storeToRefs} from "pinia";
import {computed, ref} from "vue";
import ReadingsChart from "@/components/Readings/ReadingsChart";
import MonthlyChart from "@/components/Readings/MonthlyChart";
import MonthlyTable from "@/components/Readings/MonthlyTable";

export default {
  name: "Readings",
  components: {
    MonthlyChart,
    ReadingsChart,
    CreateReadingDialog, ReadingsAll, MonthlyTable},
  mixins: [DateMixin, UserMixin, UtilsMixin],
  setup() {
    const readingsStore = useReadingsStore()
    const {selectedPeriod, compareMode, showErrors, fetchingMeters} = storeToRefs(readingsStore)
    const viewMode = ref("list");
    const grouping = ref("monthly");
    const period = computed({
      get() {
        return [selectedPeriod.value.start.format("DD/MM/YYYY"), selectedPeriod.value.end.format("DD/MM/YYYY")]
      },
      set([start, end]) {
        selectedPeriod.value.start = moment(start, "DD/MM/YYYY");
        selectedPeriod.value.end = moment(end, "DD/MM/YYYY");
      }
    })
    let currentCondominium = null;
    return {
      selectedPeriod,
      lastYearPeriod: readingsStore.lastYearPeriod,
      viewMode,
      grouping,
      compareMode,
      showErrors,
      period,
      currentCondominium,
      fetchingMeters
    }
  },
  computed: {
    ...mapGetters(["currentApartment", "isGranted", "apartmentReadings", "meterConsumptionEstimation", "currentUser"])
  },
  methods: {
    ...mapActions(["fetchApartmentReadings", "fetchApartment", "fetchMeterConsumptionEstimation"]),
    async fetchInitialData() {
      let apartmentId = this.$route.params.id;
      if (!apartmentId) {
        await this.$router.push({name: "dashboard"});
        return
      }
      if (!this.currentApartment || this.currentApartment.id !== apartmentId) {
        await this.fetchApartment(apartmentId);
      }

      // SETUP READINGS PERIOD
      const condominium = this.currentApartment.condominium;
      this.currentCondominium = condominium;
      const readingsStartDate = condominium.readingsStartDate;
      const readingsEndDate = condominium.readingsEndDate

      this.selectedPeriod.start = moment(moment().year() + "-" + readingsStartDate)
      if (moment().isBefore(this.selectedPeriod.start)) {
        this.selectedPeriod.start.subtract(1, "year")
      }
      this.selectedPeriod.end = moment(moment().year() + "-" + readingsEndDate)
      if (this.selectedPeriod.end.isBefore(this.selectedPeriod.start)) {
        this.selectedPeriod.end.add(1, "year")
      }
      this.fetchData();

    },
    setPreviousSeason() {
      this.selectedPeriod = {
        start: this.selectedPeriod.start.subtract(1, "year"),
        end: this.selectedPeriod.end.subtract(1, "year"),
      }
      this.fetchData()
    },
    setNextSeason() {
      this.selectedPeriod = {
        start: this.selectedPeriod.start.add(1, "year"),
        end: this.selectedPeriod.end.add(1, "year"),
      }
      this.fetchData()
    },
    fetchData() {
      useReadingsStore().fetchReadings(this.currentApartment.id, {
        startDate: this.selectedPeriod.start.format("YYYY-MM-DD"),
        endDate: this.selectedPeriod.end.format("YYYY-MM-DD")
      })
      if (this.compareMode) {
        this.fetchLastYearData();
      }
    },
    fetchLastYearData() {
      useReadingsStore().fetchReadings(this.currentApartment.id, {
        startDate: this.lastYearPeriod.start.format("YYYY-MM-DD"),
        endDate: this.lastYearPeriod.end.format("YYYY-MM-DD")
      })
    },
    openCreateReadingDialog() {
      this.fetchApartment(this.$route.params.id)
      this.$refs.createReadingDialog.show();
    },
    async compare() {
      this.compareMode = !this.compareMode
      this.fetchLastYearData()
    },
    toggleShowErrors() {
      this.showErrors = !this.showErrors
    },


  },
  watch: {
    async $route(to, from) {
      if (to !== from) {
        await this.fetchInitialData();
      }
    }
  },
  async mounted() {
    await this.fetchInitialData();
  },
  created() {
    this.emitter.on('update-readings', () => {
      this.fetchData();
    })
  }
}
</script>

<style scoped>
.ml-10 {
  margin-left: 10px;
}

.reading-controls {
  display: flex;
  justify-content: space-between;
}
</style>
