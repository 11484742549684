<script>
import {Line} from 'vue-chartjs'
import "chartjs-adapter-moment"
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
  TimeScale,
  LinearScale,
  CategoryScale,
} from 'chart.js'
import {computed, defineComponent} from "vue";
import {useReadingsStore} from "@/store/useReadingsStore";
import {storeToRefs} from "pinia/dist/pinia";

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    PointElement,
    TimeScale,
    LinearScale,
    CategoryScale
)
export default defineComponent({
  name: "MonthlyChart",
  components: {Line},
  setup() {
    const readingsStore = useReadingsStore();
    const {meters, selectedPeriod, compareMode} = storeToRefs(readingsStore);

    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      height: 300,
      locale: "it",
      scales: {
        x: {
          title: {
            display: true,
            text: 'Mese'
          }
        },
        y: {
          title: {
            display: true,
            text: 'Lettura'
          }
        }
      }
    }

    const readingsData = computed(() => {
      const data = {}
      if (!readingsStore.monthlyReadings) return null
      for (const id in readingsStore.monthlyReadings?.value) {
        const readings = readingsStore.monthlyReadings.value[id];
        data[id] = {
          labels: readings.map(r => r.month + "/" + r.year),
          datasets: [{
            label: 'Letture',
            data: readings.map(r => (
                r.lastReadingValue
            )),
            backgroundColor: [
              'rgba(250,50, 25, 0.2)',
            ],
            borderColor: [
              'rgba(250,50,25,1)',
            ],
            borderWidth: 1
          }]
        }
        if (compareMode.value) {
          const prevReadings = readingsStore.lastYearMonthlyReadings.value[id] ?? []
          data[id].datasets.push({
            label: 'Anno Precedente',
            data: prevReadings.map(r => (
                r.lastReadingValue
            )),
            backgroundColor: [
              'rgba(25,50, 250, 0.2)',
            ],
            borderColor: [
              'rgba(25,50,250,1)',
            ],
            borderWidth: 1
          })
        }
      }
      return data;
    })

    const consumptionData = computed(() => {
      const data = {}
      if (!readingsStore.monthlyReadings) return data

      for (const id in readingsStore.monthlyReadings?.value) {
        const readings = readingsStore.monthlyReadings.value[id];
        data[id] = {
          labels: readings.map(r => r.month + "/" + r.year),
          datasets: [{
            label: 'Consumi',
            data: readings.map(r => (
                r.consumption
            )),
            backgroundColor: [
              'rgba(250,50, 25, 0.2)',
            ],
            borderColor: [
              'rgba(250,50,25,1)',
            ],
            borderWidth: 1
          }]
        }
        if (compareMode.value) {
          const prevReadings = readingsStore.lastYearMonthlyReadings.value[id] ?? []
          data[id].datasets.push({
            label: 'Anno Precedente',
            data: prevReadings.map(r => (
                r.consumption
            )),
            backgroundColor: [
              'rgba(25,50, 250, 0.2)',
            ],
            borderColor: [
              'rgba(25,50,250,1)',
            ],
            borderWidth: 1
          })
        }
      }
      return data;
    })


    return {
      meters,
      filteredReadings: readingsStore.filteredReadings,
      filteredLastYearReadings: readingsStore.filteredLastYearReadings,
      lastYearPeriod: readingsStore.lastYearPeriod,
      chartOptions,
      readingsData,
      consumptionData,
      selectedPeriod,
      compareMode
    }
  },
  methods: {
    stringifyDate(date) {
      return date.format("DD/MM/YYYY")
    },
  }
})
</script>

<template>
  <el-tabs type="border-card" v-if="readingsData">
    <el-tab-pane label="Totali">
      <Line
          :chartData="readingsData.total"
          :chartOptions="chartOptions"
          chartId="total-r"
          class="chart-container"
      ></Line>
      <div class="readings-heading">Consumi mensili</div>
      <Line
          :chartData="consumptionData.total"
          :chartOptions="chartOptions"
          chartId="total-c"
          class="chart-container"
      ></Line>
    </el-tab-pane>
    <el-tab-pane v-for="meter in meters" :label="meter.description" :key="meter.serialNumber">
      <el-descriptions class="margin-top" title="Contatore" :column="3" border>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-user"></i>
            Matricola
          </template>
          {{ meter.serialNumber }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-user"></i>
            Marca
          </template>
          {{ meter.brand }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-user"></i>
            K
          </template>
          {{ meter.kMeter }}
        </el-descriptions-item>
      </el-descriptions>

      <div class="readings-heading">Letture mensili</div>
      <Line
          :chartData="readingsData[meter.id]"
          :chartOptions="chartOptions"
          :chartId="meter.serialNumber+'r'"
          class="chart-container"
      ></Line>
      <div class="readings-heading">Consumi mensili</div>
      <Line
          :chartData="consumptionData[meter.id]"
          :chartOptions="chartOptions"
          :chartId="meter.serialNumber+'c'"
          class="chart-container"
      ></Line>
    </el-tab-pane>
  </el-tabs>
</template>

<style scoped>
.readings-heading {
  padding: 10px;
  color: #909399;
  font-weight: bold;
}

/deep/
.chart-container {
  height: 300px;
}


</style>