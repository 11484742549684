<template>
  <el-tabs type="border-card" v-if="!fetchingMeters">
    <el-tab-pane label="Totali">
      <el-row>
        <el-col :span="compareMode ? 12 : 24">
          <div class="readings-heading">
            Totali mensili dal {{ stringifyDate(selectedPeriod.start) }} al {{ stringifyDate(selectedPeriod.end) }}
          </div>
          <el-table :data="monthlyReadings.total" style="width: 100%">
            <el-table-column prop="date" label="Mese">
              <template #default="scope">
                {{ scope?.row?.month ?? "" }}/{{ scope?.row?.year ?? "" }}
              </template>
            </el-table-column>
            <el-table-column prop="value" label="Lettura">
              <template #default="scope">
                {{ scope?.row?.lastReadingValue ?? "" }}
                {{ scope?.row?.first ? "(Iniziale)" : "" }}
              </template>
            </el-table-column>
            <el-table-column prop="value" label="Consumo">
              <template #default="scope">
                {{ scope?.row?.consumption ?? "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="value" label="Data Lettura">
              <template #default="scope">
                {{ scope?.row?.lastReadingDate ? stringifyDate(scope?.row?.lastReadingDate) : "Non pervenuta" }}
              </template>
            </el-table-column>
          </el-table>
        </el-col>
        <el-col v-if="compareMode" :span="12">
          <div class="readings-heading">Totali Mensili dal {{ stringifyDate(lastYearPeriod.start) }} al
            {{ stringifyDate(lastYearPeriod.end) }}
          </div>
          <el-table :data="lastYearMonthlyReadings.total" style="width: 100%">
            <el-table-column prop="date" label="Mese">
              <template #default="scope">
                {{ scope?.row?.month ?? "" }}/{{ scope?.row?.year ?? "" }}
              </template>
            </el-table-column>
            <el-table-column prop="value" label="Lettura">
              <template #default="scope">
                {{ scope?.row?.lastReadingValue ?? "" }}
                {{ scope?.row?.first ? "(Iniziale)" : "" }}

              </template>
            </el-table-column>
            <el-table-column prop="value" label="Consumo">
              <template #default="scope">
                {{ scope?.row?.consumption ?? "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="value" label="Data Lettura">
              <template #default="scope">
                {{ scope?.row?.lastReadingDate ? stringifyDate(scope?.row?.lastReadingDate) : "Non pervenuta" }}
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </el-tab-pane>
    <el-tab-pane v-for="meter in meters" :label="meter.description" :key="meter.id">
      <el-descriptions class="margin-top" title="Contatore" :column="3" border>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-user"></i>
            Matricola
          </template>
          {{ meter.serialNumber }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-user"></i>
            Marca
          </template>
          {{ meter.brand }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-user"></i>
            K
          </template>
          {{ meter.kMeter }}
        </el-descriptions-item>
      </el-descriptions>

      <el-row>
        <el-col :span="compareMode ? 12 : 24">
          <div class="readings-heading">
            Letture mensili dal {{ stringifyDate(selectedPeriod.start) }} al {{ stringifyDate(selectedPeriod.end) }}
          </div>
          <el-alert v-if="filteredReadings[meter.id]?.length===0"
                    title="Nessuna lettura presente per il periodo selezionato"
                    type="warning" :closable="false" show-icon></el-alert>
          <template v-else>
            <el-table :data="monthlyReadings[meter.id]" style="width: 100%">
              <el-table-column prop="date" label="Mese">
                <template #default="scope">
                  {{ scope?.row?.month ?? "" }}/{{ scope?.row?.year ?? "" }}
                </template>
              </el-table-column>
              <el-table-column prop="value" label="Lettura">
                <template #default="scope">
                  {{ scope?.row?.lastReadingValue ?? "" }}
                </template>
              </el-table-column>
              <el-table-column prop="value" label="Consumo">
                <template #default="scope">
                  {{ scope?.row?.consumption ?? "-" }}
                </template>
              </el-table-column>
              <el-table-column prop="value" label="Data Lettura">
                <template #default="scope">
                  {{ scope?.row?.lastReadingDate ? stringifyDate(scope?.row?.lastReadingDate) : "Non pervenuta" }}
                </template>
              </el-table-column>
              <el-table-column prop="value" label="Note">
                <template #default="scope">
                  {{ scope?.row?.first ? "Iniziale " : "" }}
                  {{ scope?.row?.errors ?? "" }}
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-col>

        <el-col v-if="compareMode" :span="12">
          <div class="readings-heading">Letture mensili dal {{ stringifyDate(lastYearPeriod.start) }} al
            {{ stringifyDate(lastYearPeriod.end) }}
          </div>

          <el-alert v-if="filteredLastYearReadings[meter.id]?.length===0"
                    title="Nessuna lettura presente per l'anno precedente"
                    type="warning" :closable="false" show-icon></el-alert>
          <template v-else>
            <el-table :data="lastYearMonthlyReadings[meter.id]" style="width: 100%">
              <el-table-column prop="date" label="Mese">
                <template #default="scope">
                  {{ scope?.row?.month ?? "" }}/{{ scope?.row?.year ?? "" }}
                </template>
              </el-table-column>
              <el-table-column prop="value" label="Lettura">
                <template #default="scope">
                  {{ scope?.row?.lastReadingValue ?? "" }}
                </template>
              </el-table-column>
              <el-table-column prop="value" label="Consumo">
                <template #default="scope">
                  {{ scope?.row?.consumption ?? "-" }}
                </template>
              </el-table-column>
              <el-table-column prop="value" label="Data Lettura">
                <template #default="scope">
                  {{
                    scope?.row?.lastReadingDate ? stringifyDate(scope?.row?.lastReadingDate) : "Non pervenuta"
                  }}
                </template>
              </el-table-column>
              <el-table-column prop="value" label="Note">
                <template #default="scope">
                  {{ scope?.row?.first ? "Iniziale " : "" }}
                  {{ scope?.row?.errors ?? "" }}
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-col>
      </el-row>
    </el-tab-pane>
  </el-tabs>
</template>

<script>

import {useReadingsStore} from "@/store/useReadingsStore";
import {storeToRefs} from "pinia/dist/pinia";

export default {
  name: "ReadingsAll",
  components: {},
  mixins: [],
  setup() {
    const readingsStore = useReadingsStore();
    const {meters, showErrors, compareMode, selectedPeriod, fetchingMeters} = storeToRefs(readingsStore);

    return {
      meters,
      fetchingMeters,
      showErrors,
      compareMode,
      selectedPeriod,
      filteredReadings: readingsStore.filteredReadings,
      filteredLastYearReadings: readingsStore.filteredLastYearReadings,
      lastYearPeriod: readingsStore.lastYearPeriod,
      monthlyReadings: readingsStore.monthlyReadings,
      lastYearMonthlyReadings: readingsStore.lastYearMonthlyReadings,
      meterTypes: [
        {
          id: 1,
          label: "Calore"
        },
        {
          id: 2,
          label: "Acqua Calda"
        },
        {
          id: 3,
          label: "Acqua Fredda"
        },
        {
          id: 4,
          label: "Ripartitore"
        },
      ],
    }
  },
  methods: {
    stringifyDate(date) {
      return date.format("DD/MM/YYYY")
    },
  },
  created() {
  },
  mounted() {
  }
}
</script>

<style scoped>
.readings-heading {
  padding: 10px;
  color: #909399;
  font-weight: bold;
}

</style>
