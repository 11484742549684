const UtilsMixin = {
    methods: {
        getCurrentTenant: apartment => {
            return apartment?.tenants?.find(tenant => tenant.endDate===null)
        },
        getCurrentTenantName: apartment => {
            if (apartment){
                let tenant = apartment.tenants.find(tenant => tenant.endDate===null)
                if (tenant){
                    return tenant.name ?? "" + " " + tenant.surname ?? ""
                }
                
            }
            return ""
        }
    }
}

export default UtilsMixin