<template>
  <div v-if="meters">
    <el-tabs type="border-card">
      <el-tab-pane label="Totali">
        <el-row>
          <el-col :span="compareMode ? 12 : 24">
            <div class="readings-heading">Letture dal {{ stringifyDate(selectedPeriod.start) }} al
              {{ stringifyDate(selectedPeriod.end) }}
            </div>
            <el-alert v-if="totalReadings.length===0"
                      title="Nessuna lettura presente per il periodo selezionato"
                      type="warning" :closable="false" show-icon></el-alert>
            <template v-else>
              <el-table :data="totalReadings" style="width: 100%">
                <el-table-column prop="date" label="Data">
                  <template #default="scope">
                    {{ stringifyDate(scope?.row?.dateTime) }}
                  </template>
                </el-table-column>
                <el-table-column prop="date" label="Contatore">
                  <template #default="scope">
                    {{ scope?.row?.meter }}
                  </template>
                </el-table-column>
                <el-table-column prop="value" label="Lettura">
                  <template #default="scope">
                    {{ scope?.row?.value ?? "" }}
                    {{ scope?.row?.first ? "(Iniziale)" : "" }}

                  </template>
                </el-table-column>
                <el-table-column label="Errore" v-if="showErrors">
                  <template #default="scope">
                    {{ scope?.row?.error ?? "" }}
                  </template>
                </el-table-column>
              </el-table>
            </template>

          </el-col>

          <el-col v-if="compareMode" :span="12">
            <div class="readings-heading">Letture dal {{ stringifyDate(lastYearPeriod.start) }} al
              {{ stringifyDate(lastYearPeriod.end) }}
            </div>

            <el-alert v-if="lastYearTotalReadings?.length===0"
                      title="Nessuna lettura presente per l'anno precedente"
                      type="warning" :closable="false" show-icon></el-alert>
            <template v-else>
              <el-table :data="lastYearTotalReadings" style="width: 100%">
                <el-table-column prop="date" label="Data">
                  <template #default="scope">
                    {{ stringifyDate(scope?.row?.dateTime) }}
                  </template>
                </el-table-column>
                <el-table-column prop="date" label="Contatore">
                  <template #default="scope">
                    {{ scope?.row?.meter }}
                  </template>
                </el-table-column>
                <el-table-column prop="value" label="Lettura">
                  <template #default="scope">
                    {{ scope?.row?.value ?? "" }}
                    {{ scope?.row?.first ? "(Iniziale)" : "" }}
                  </template>
                </el-table-column>
                <el-table-column label="Errore" v-if="showErrors">
                  <template #default="scope">
                    {{ scope?.row?.error ?? "" }}
                  </template>
                </el-table-column>
              </el-table>
            </template>

          </el-col>
        </el-row>
      </el-tab-pane>

      <el-tab-pane v-for="meter in meters" :label="meter.description" :key="meter.id">
        <el-descriptions class="margin-top" title="Contatore" :column="3" border>
          <el-descriptions-item>
            <template #label>
              <i class="el-icon-user"></i>
              Matricola
            </template>
            {{ meter.serialNumber }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label>
              <i class="el-icon-user"></i>
              Marca
            </template>
            {{ meter.brand }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label>
              <i class="el-icon-user"></i>
              K
            </template>
            {{ meter.kMeter }}
          </el-descriptions-item>
        </el-descriptions>
        <el-row>
          <el-col :span="compareMode ? 12 : 24">
            <div class="readings-heading">Letture dal {{ stringifyDate(selectedPeriod.start) }} al
              {{ stringifyDate(selectedPeriod.end) }}
            </div>
            <el-alert v-if="filteredReadings[meter.id]?.length===0"
                      title="Nessuna lettura presente per il periodo selezionato"
                      type="warning" :closable="false" show-icon></el-alert>
            <template v-else>
              <el-table :data="filteredReadings[meter.id]" style="width: 100%">
                <el-table-column prop="date" label="Data">
                  <template #default="scope">
                    {{ stringifyDate(scope?.row?.dateTime) }}
                  </template>
                </el-table-column>
                <el-table-column prop="value" label="Lettura">
                  <template #default="scope">
                    {{ scope?.row?.value ?? "" }}
                    {{ scope?.row?.first ? "(Iniziale)" : "" }}

                  </template>
                </el-table-column>
                <el-table-column label="Errore" v-if="showErrors">
                  <template #default="scope">
                    {{ scope?.row?.error ?? "" }}
                  </template>
                </el-table-column>
              </el-table>
            </template>

          </el-col>

          <el-col v-if="compareMode" :span="12">
            <div class="readings-heading">Letture dal {{ stringifyDate(lastYearPeriod.start) }} al
              {{ stringifyDate(lastYearPeriod.end) }}
            </div>

            <el-alert v-if="filteredLastYearReadings[meter.id]?.length===0"
                      title="Nessuna lettura presente per l'anno precedente"
                      type="warning" :closable="false" show-icon></el-alert>
            <template v-else>
              <el-table :data="filteredLastYearReadings[meter.id]" style="width: 100%">
                <el-table-column prop="date" label="Data">
                  <template #default="scope">
                    {{ stringifyDate(scope?.row?.dateTime) }}
                  </template>
                </el-table-column>
                <el-table-column prop="value" label="Lettura">
                  <template #default="scope">
                    {{ scope?.row?.value ?? "" }}
                    {{ scope?.row?.first ? "(Iniziale)" : "" }}
                  </template>
                </el-table-column>
                <el-table-column label="Errore" v-if="showErrors">
                  <template #default="scope">
                    {{ scope?.row?.error ?? "" }}
                  </template>
                </el-table-column>
              </el-table>
            </template>

          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>

import {useReadingsStore} from "@/store/useReadingsStore";
import {storeToRefs} from "pinia/dist/pinia";
import {computed} from "vue";

export default {
  name: "ReadingsAll",
  components: {},
  mixins: [],
  setup() {
    const readingsStore = useReadingsStore();
    const {meters, showErrors, compareMode, selectedPeriod, filteredReadings} = storeToRefs(readingsStore);

    const totalReadings = computed(() => {
      const readings = [];
      Object.values(meters?.value)?.forEach(m => {
        filteredReadings?.value[m.id]?.forEach(r => {
          readings.push({...r, meter: m.description});
        })
      })
      return readings.sort((a, b) => a.dateTime.isAfter(b.dateTime) ? 1 : -1)
    })
    const lastYearTotalReadings = computed(() => {
      const readings = [];
      Object.values(meters?.value)?.forEach(m => {
        readingsStore?.filteredLastYearReadings?.value[m.id]?.forEach(r => {
          readings.push({...r, meter: m.description});
        })
      })
      return readings.sort((a, b) => a.dateTime.isAfter(b.dateTime) ? 1 : -1)
    })

    return {
      meters,
      showErrors,
      compareMode,
      selectedPeriod,
      filteredReadings: readingsStore.filteredReadings,
      filteredLastYearReadings: readingsStore.filteredLastYearReadings,
      lastYearPeriod: readingsStore.lastYearPeriod,
      totalReadings,
      lastYearTotalReadings,
      meterTypes: [
        {
          id: 1,
          label: "Calore"
        },
        {
          id: 2,
          label: "Acqua Calda"
        },
        {
          id: 3,
          label: "Acqua Fredda"
        },
        {
          id: 4,
          label: "Ripartitore"
        },
      ],
    }
  },
  methods: {
    stringifyDate(date) {
      return date.format("DD/MM/YYYY")
    },
  },
  created() {
  },
  mounted() {
  }
}
</script>

<style scoped>
.readings-heading {
  padding: 10px;
  color: #909399;
  font-weight: bold;
}

</style>
