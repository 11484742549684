<script>
import {Line} from 'vue-chartjs'
import "chartjs-adapter-moment"
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
  TimeScale,
  LinearScale,
} from 'chart.js'
import {computed, defineComponent} from "vue";
import {useReadingsStore} from "@/store/useReadingsStore";
import {storeToRefs} from "pinia/dist/pinia";
import moment from "moment";

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    PointElement,
    TimeScale,
    LinearScale,
)
export default defineComponent({
  name: "ReadingsChart",
  components: {Line},
  setup() {
    const readingsStore = useReadingsStore();
    const {meters, selectedPeriod, compareMode} = storeToRefs(readingsStore);

    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      locale: "it",
      scales: {
        x: {
          type: 'time',
          time: {
            tooltipFormat: 'DD/MM/YY',
            displayFormats: {
              day: 'DD/MM/YY'
            },
          },
          title: {
            display: true,
            text: 'Data'
          }
        },
        y: {
          title: {
            display: true,
            text: 'Lettura'
          }
        }
      }
    }

    const chartData = computed(() => {
      const data = {}
      for (const id in readingsStore.filteredReadings?.value) {
        const readings = readingsStore.filteredReadings.value[id];
        data[id] = {
          datasets: [{
            label: 'Letture',
            data: readings.map(r => ({
              x: r.dateTime.format(),
              y: r.value
            })),
            backgroundColor: [
              'rgba(250,50,25,.2)',
            ],
            borderColor: [
              'rgba(250,50,25,1)',
            ],
            borderWidth: 1
          }]
        }
        if(compareMode.value){
          const lastYearReadings = readingsStore.filteredLastYearReadings.value[id];
          data[id].datasets.push({
            label: 'Anno precedente',
            data: lastYearReadings.map(r => ({
              x: moment(r.dateTime).add(1, "year").format(),
              y: r.value
            })),
            backgroundColor: [
              'rgba(25,50,250,.2)',
            ],
            borderColor: [
              'rgba(25,50,250,1)',
            ],
            borderWidth: 1
          })
        }
      }
      return data;
    })

    const chartConsumptionData = computed(() => {
      const data = {}
      for (const id in readingsStore.filteredReadings?.value) {
        const readings = readingsStore.filteredReadings.value[id];
        data[id] = {
          datasets: [{
            label: 'Consumi',
            data: readings.map(r => ({
              x: r.dateTime.format(),
              y: r.consumption
            })),
            backgroundColor: [
              'rgba(250,50,25,.2)',
            ],
            borderColor: [
              'rgba(250,50,25,1)',
            ],
            borderWidth: 1
          }]
        }
        if(compareMode.value){
          const lastYearReadings = readingsStore.filteredLastYearReadings.value[id];
          data[id].datasets.push({
            label: 'Anno precedente',
            data: lastYearReadings.map(r => ({
              x: moment(r.dateTime).add(1, "year").format(),
              y: r.consumption
            })),
            backgroundColor: [
              'rgba(25,50,250,.2)',
            ],
            borderColor: [
              'rgba(25,50,250,1)',
            ],
            borderWidth: 1
          })
        }
      }
      return data;
    })


    return {
      meters,
      filteredReadings: readingsStore.filteredReadings,
      filteredLastYearReadings: readingsStore.filteredLastYearReadings,
      lastYearPeriod: readingsStore.lastYearPeriod,
      chartOptions,
      chartData,
      chartConsumptionData,
      selectedPeriod,
      compareMode
    }
  },
  methods: {
    stringifyDate(date) {
      return date.format("DD/MM/YYYY")
    },
  }
})
</script>

<template>
  <el-tabs type="border-card">
    <el-tab-pane v-for="meter in meters" :label="meter.description" :key="meter.serialNumber">
      <el-descriptions class="margin-top" title="Contatore" :column="3" border>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-user"></i>
            Matricola
          </template>
          {{ meter.serialNumber }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-user"></i>
            Marca
          </template>
          {{ meter.brand }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-user"></i>
            K
          </template>
          {{ meter.kMeter }}
        </el-descriptions-item>
      </el-descriptions>

      <div class="readings-heading">Letture dal {{ stringifyDate(selectedPeriod.start) }} al
        {{ stringifyDate(selectedPeriod.end) }}
      </div>
      <el-alert
          v-if="filteredReadings[meter.id]?.length===0 && filteredLastYearReadings[meter.id]?.length===0"
          title="Nessuna lettura presente per il periodo selezionato"
          type="warning" :closable="false" show-icon>
      </el-alert>
      <template v-else>
        <Line
            :chartData="chartData[meter.id]"
            :chartOptions="chartOptions"
            :chartId="meter.serialNumber"
            class="chart-container"

        ></Line>
        <Line
            :chartData="chartConsumptionData[meter.id]"
            :chartOptions="chartOptions"
            :chartId="meter.serialNumber"
            class="chart-container"
        ></Line>
      </template>

    </el-tab-pane>
  </el-tabs>
</template>

<style scoped>
.readings-heading {
  padding: 10px;
  color: #909399;
  font-weight: bold;
}

/deep/
.chart-container {
  height: 300px;
}


</style>