import {defineStore} from 'pinia'
import {getApartmentReadings} from "@/api/apartment.api";
import moment from "moment";
import {computed} from "vue";

const getMonthlyReadings = (meters, period) => {
    const metersReadings = {}
    const totalReadings = {}
    for (let bracket = moment(period.start); bracket.isBefore(period.end); bracket.add(1, "month")) {
        totalReadings[bracket.format("MM-YYYY")] = {
            month: bracket.format("MM"),
            year: bracket.format("YYYY"),
            lastReadingValue: 0,
            lastReadingDate: null,
            readings: [],
            consumption: 0,
            first: false,
        }
    }
    Object.values(meters).forEach(meter => {
        const readings = {}
        // Create Empty Months
        for (let bracket = moment(period.start); bracket.isBefore(period.end); bracket.add(1, "month")) {
            readings[bracket.format("MM-YYYY")] = {
                month: bracket.format("MM"),
                year: bracket.format("YYYY"),
                lastReadingValue: 0,
                lastReadingDate: null,
                readings: [],
                consumption: 0,
                first: false,
            }
        }
        // Fills With Data
        Object.values(meter.readings).forEach(r => {
            const br = readings[r.dateTime.format("MM-YYYY")];
            if (br) {
                br.readings.push(r);
                if (!br.lastReadingDate || r.dateTime.isAfter(br.lastReadingDate)) {
                    br.lastReadingDate = r.dateTime;
                    br.lastReadingValue = r.value;
                    if (!br.first && r.first) br.first = true;
                }
            }
        });
        // Add To meter readings and adding missing data
        let lastReading = 0
        metersReadings[meter.id] = Object.values(readings)
        metersReadings[meter.id].forEach((br) => {
            if (!br.lastReadingDate) {
                br.lastReadingValue = lastReading;
            }
            // Check for negative consumptions

            if (br.lastReadingValue < lastReading) {
                br.lastReadingValue = lastReading
                br.errors = "Inferiore alla precedente "
            }

            br.consumption = br.lastReadingValue - lastReading;

            lastReading = br.lastReadingValue;

            // Adding to totals
            const totalObj = totalReadings[br.month + "-" + br.year];
            totalObj.lastReadingValue += br.lastReadingValue;
            totalObj.consumption += br.consumption;
            totalObj.first |= br.first
            if (!totalObj.lastReadingDate || br.lastReadingDate?.isAfter(totalObj.lastReadingDate)) {
                totalObj.lastReadingDate = br.lastReadingDate;
            }
        });
    });
    metersReadings.total = Object.values(totalReadings);
    return metersReadings
}

export const useReadingsStore = defineStore('readings', {
    state: () => ({
        fetchingMeters: false,
        meters: {},
        selectedPeriod: {
            start: moment(),
            end: moment()
        },
        compareMode: false,
        showErrors: false
    }),
    getters: {
        lastYearPeriod() {
            return computed(() => {
                return {
                    start: moment(this.selectedPeriod.start).subtract(1, "year"),
                    end: moment(this.selectedPeriod.end).subtract(1, "year")
                }
            })
        },
        filteredReadings() {
            if (this.fetchingMeters) return
            return computed(() => {
                const readings = {};
                Object.values(this.meters).forEach(meter => {
                    readings[meter.id] =
                        Object.values(meter.readings)
                            .filter(r => !(r.dateTime.isBefore(this.selectedPeriod.start) || r.dateTime.isAfter(this.selectedPeriod.end)))
                            .sort((a, b) => a.dateTime.isAfter(b.dateTime) ? 1 : -1)
                    let lastReading = readings[meter.id][0] ?? 0;
                    readings[meter.id] = readings[meter.id].map(r => {
                        const reading = r;
                        if (r.first) {
                            reading.consumption = 0;
                        } else {
                            reading.consumption = r.value - lastReading;
                        }
                        lastReading = r.value;
                        return reading;
                    })
                })
                return readings
            })
        },
        filteredLastYearReadings() {
            if (this.fetchingMeters) return
            return computed(() => {
                const readings = {};
                Object.values(this.meters).forEach(meter => {
                    readings[meter.id] =
                        Object.values(meter.readings)
                            .filter(r => !(r.dateTime.isBefore(this.lastYearPeriod.value.start) || r.dateTime.isAfter(this.lastYearPeriod.value.end)))
                            .sort((a, b) => a.dateTime.isAfter(b.dateTime) ? 1 : -1)
                    let lastReading = readings[meter.id][0] ?? 0;
                    readings[meter.id] = readings[meter.id].map(r => {
                        const reading = r;
                        if (r.first) {
                            reading.consumption = 0;
                        } else {
                            reading.consumption = r.value - lastReading;
                        }
                        lastReading = r.value;
                        return reading;
                    })
                })
                return readings
            })
        },
        monthlyReadings() {
            if (this.fetchingMeters) return
            return computed(() => {
                return getMonthlyReadings(this.meters, this.selectedPeriod);
            })
        },
        lastYearMonthlyReadings() {
            if (this.fetchingMeters) return
            return computed(() => {
                if (this.meters && !this.fetchingMeters) return getMonthlyReadings(this.meters, this.lastYearPeriod.value);
                return null

            })
        }
    },
    actions: {
        async fetchReadings(apartmentId, query) {
            this.fetchingMeters = true
            this.meters = {};
            try {
                const response = await getApartmentReadings(apartmentId, query);
                Object.values(response.data.readings).forEach(m => {
                    let meter;
                    if (this.meters[m.id]) {
                        meter = this.meters[m.id]
                    } else {
                        meter = {
                            active: m.active,
                            id: m.id,
                            serialNumber: m.serialNumber,
                            brand: m.brand,
                            meterType: m.meterType,
                            kMeter: m.kMeter,
                            description: m.description,
                            replaced: m.replaced,
                            readings: {}
                        }
                        this.meters[m.id] = meter
                    }
                    m.readings.forEach(r => {
                        meter.readings[r.id] = {...r, dateTime: moment(r.dateTime)};
                    })
                });
                this.meters = {...this.meters};
            } catch (e) {
                console.error(e)
            }
            this.fetchingMeters = false

        },
    },
})